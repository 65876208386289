/* ============================================================================================== */
/*                                          DATE HELPERS                                          */
/* ============================================================================================== */

/* ========================================= Date Arrays ======================================== */
const ArrayOfMonths = [
  'Januari',
  'Februari',
  'Maret',
  'April',
  'Mei',
  'Juni',
  'Juli',
  'Agustus',
  'September',
  'Oktober',
  'November',
  'Desember',
]

const ArrayOfDays = [
  'Minggu',
  'Senin',
  'Selasa',
  'Rabu',
  'Kamis',
  'Jumat',
  'Sabtu',
]

/* ======================================== Date Convert ======================================== */
export const monthIndo = (intInput) => ArrayOfMonths[intInput]
export const dayIndo   = (intInput) => ArrayOfDays[intInput]

/* ========================================= Date Format ======================================== */
export const formatDate = (ISO_input, full = true) => {
  const d = new Date(ISO_input)
  const month = monthIndo(d.getMonth())
  const day = dayIndo(d.getDay())
  const date = d.getDate()
  const year = d.getFullYear()
  const hour = String(d.getHours()).padStart(2, "0");  
  const min = String(d.getMinutes()).padStart(2, "0");  

  return full
    ? `${day}, ${date} ${month} ${year} (Pukul ${hour}:${min})`
    : `${day}, ${date} ${month} ${year}`
}
