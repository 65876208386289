import { useEffect } from "react"
import { useHistory } from "react-router-dom"

export default function useConfig() {
  const history = useHistory()

  /* ---------------------------------- DATA ---------------------------------- */
  const listConfigs = [
    {
      label: "Pencegahan Multi Tab",
      key: "prevent-multitab",
    },
  ]

  /* =================================== Consume Local Storage ================================== */
  const roleActive = JSON.parse(localStorage.getItem("role_active"))

  function checkUserHasAccessConfig() {
    const menu_keys = localStorage.getItem("menu_key")
    const menu_keysJSON = menu_keys && JSON.parse(menu_keys)

    if (Array.isArray(menu_keysJSON) && menu_keysJSON.length) {
      if (!menu_keysJSON.includes("config")) {
        history.push("/dashboard")
      }
    }

    if (roleActive?.role_type == "super") {
      listConfigs.unshift({
        label: "Quiz",
        key: "quiz",
      })
    }
  }

  useEffect(() => {
    checkUserHasAccessConfig()
  }, [])

  return {
    data: {
      listConfigs,
    },
  }
}
