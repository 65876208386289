import React, { useCallback, useEffect, useState } from "react"
import { Form } from "react-bootstrap"
import { useBaseContext } from "context/base"
import BaseButton from "component/button/BaseButton"
import Swal from "sweetalert2"

const ConfigPreventMultitab = () => {
  /* ====================================== Consume Context ====================================== */
  const { getRequest, putRequest } = useBaseContext()

  const [value, setValue] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)

  /* --------------------------------- HANDLER -------------------------------- */
  const getDataHandler = useCallback(async () => {
    setIsLoading(true)

    try {
      let response = await getRequest("config?config_name=prevent_multitabs")

      if (response) {
        const data = response?.data

        setIsLoading(false)
        setValue(data?.config_value || "0")
      } else {
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      window.notification(
        "Error Get Config Pencegahan Multi Tab",
        error?.message || "Something went wrong",
        "error"
      )
      return []
    }
  }, [])

  function handleChangeValue(event) {
    switch (event.target.checked) {
      case true:
        setValue("1")
        break
      case false:
        setValue("0")
        break
      default:
        break
    }
  }

  async function handleSubmit(e) {
    e.preventDefault()

    const payload = {
      config_name: "prevent_multitabs",
      config_value: value,
      active: true,
    }

    Swal.fire({
      title: "Konfirmasi",
      text: "Anda akan menyimpan data konfigurasi Pencegahan Multi Tab ini?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya, Simpan!",
      cancelButtonText: "Tidak",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoadingSubmit(true)
        const response = await putRequest("config", payload)

        if (response) {
          setIsLoadingSubmit(false)
          window.notification(
            "Berhasil menyimpan data konfigurasi pencegahan multi tab!",
            "",
            "success"
          )
        } else {
          setIsLoadingSubmit(false)
        }
      }
    })
  }

  /* ---------------------------------- HOOKS --------------------------------- */
  useEffect(() => {
    getDataHandler()
  }, [])

  return (
    <Form onSubmit={handleSubmit} className="border p-3">
      <fieldset>
        <legend>
          <strong>Pencegahan Multi Tab</strong>
        </legend>
        <p className="m-0">
          Konfigurasi penggunaan fitur Pencegahan multi tab di aplikasi siswa.
        </p>
        <hr />

        <div className="form-group mb-3 row">
          <label
            className="col-form-label col-sm-3"
            htmlFor="switch-config-multitab"
          >
            <span className="mr-1">Aktifkan Pencegahan Multi Tab</span>
          </label>
          <div className="form-group col-sm-9">
            <div className="switch switch-primary d-inline m-r-10">
              <input
                onChange={(event) => handleChangeValue(event)}
                checked={value == '1'}
                type="checkbox"
                id="switch-config-multitab"
              />
              <label htmlFor="switch-config-multitab" className="cr"></label>
            </div>
            <label htmlFor="switch-config-multitab">
              {value == '1' ? "Aktif" : "Tidak Aktif"}
            </label>
          </div>
        </div>

        <BaseButton
          isLoading={isLoadingSubmit || isLoading}
          variant="primary"
          type="submit"
        >
          Simpan
        </BaseButton>
      </fieldset>
    </Form>
  )
}

export default ConfigPreventMultitab
